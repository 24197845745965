<template>
  <div id="app">
    <div class="main-container">
      <div :class="['header', activeIndex]">
        <div class="main-context">
          <div class="header-menus">
            <img class="logo" src="./assets/images/logo@2x.png">
            <div class="menus">
              <el-menu
                :default-active="activeIndex"
                :background-color="'transparent'"
                :active-text-color="'#ffffff'"
                class="el-menu-demo"
                mode="horizontal"
                :router="true"
                @select="handleSelect"
              >
                <el-menu-item index="home">
                  首页
                </el-menu-item>
                <el-menu-item index="news">
                  新闻动态
                </el-menu-item>
                <el-menu-item index="contact">
                  联系我们
                </el-menu-item>
                <el-menu-item index="about">
                  加入我们
                </el-menu-item>
                <el-menu-item index="go">
                  <div class="goToXLG">
                    礼享购商城
                  </div>
                </el-menu-item>
              </el-menu>
            </div>
          </div>
        </div>
      </div>
      <router-view />
      <div class="footer">
        <div class="main-context">
          <div class="footer1">
            <el-breadcrumb>
              <el-breadcrumb-item>
                <span @click="goTo('/about')">关于我们</span>
              </el-breadcrumb-item>
              <el-breadcrumb-item>
                <span @click="goTo('/contact')">商务合作</span>
              </el-breadcrumb-item>
              <el-breadcrumb-item>
                <span @click="goTo('/about')">常见问题</span>
              </el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="footer2">
            <div class="footer2-title">
              客服电话：
            </div>
            <div class="footer2-context">
              400-000-000
            </div>
            <div class="footer2-title">
              举报电话：
            </div>
            <div class="footer2-context">
              jubao@lixianggo.com
            </div>
            <div class="footer2-title">
              投诉电话：
            </div>
            <div class="footer2-context">
              400-000-000
            </div>
          </div>
          <div class="footer3">
            版权所有2021-2022江苏秧苗供应链管理有限公司 苏ICP备2021013521号
          </div>
          <div class="footer4">
            <a href="http://www.cyberpolice.cn/" target="_blank">
              <img
                src="./assets/images/网络警察@2x.png"
              ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 'home'
    }
  },
  computed: {
    routerPath() {
      return this.$route.path
    }
  },
  watch: {
    $route(to, from) {
      if (to) {
        if (to.path !== '/go') {
          this.activeIndex = to.path.replace('/', '')
          this.activeIndex = this.activeIndex ? this.activeIndex : 'home'
        }
      }
    }
  },
  created() {
    this.activeIndex = this.routerPath.replace('/', '')
    this.activeIndex = this.activeIndex ? this.activeIndex : 'home'
  },
  methods: {
    handleSelect(key, keyPath) {
      if (key === 'go') {
        // window.open('https://www.baidu.com', '_blank')
        this.$message({
          showClose: true,
          message: '礼享购商城正在建设中，尽情期待...',
          type: 'warning'
        })
      } else {
        this.activeIndex = key
      }
    },
    goTo(path) {
      if (path !== '/' + this.activeIndex) {
        this.$router.push({
          path: path
        })
      }
    }
  }
}
</script>

<style lang="scss">
  *,
  html,
  body,
  :after,
  :before {
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  #app {
    display: flex;
    justify-content: center;
    color: #17254c;
    background: #fff;
  }

  .main-container {
    height: auto;
    width: 100%;
  }

  .main-context {
    width: 1160px;
    min-width: 1160px;
  }

  .header,
  .bodyer,
  .footer {
    display: flex;
    justify-content: center;
  }

  .bodyer {
    margin-bottom: 40px;
  }

  .header {
    height: 700px;

    .header-menus {
      display: flex;
      justify-content: space-between;

      .logo {
        width: 256px;
        height: 112px;
      }

      .goToXLG {
        display: inline;
        padding: 10px 10px 10px 44px;
        box-shadow: 1px 2px 8px 0px #124bf0;
        border-radius: 4px;

        background: #fff url("./assets/images/礼物盒@2x.png") 10px center / 26px
          24px no-repeat;

        font-size: 16px;
        color: #124cf0;
      }
    }

    &.home {
      background: url("./assets/images/图层 20@2x.png") top center no-repeat;
      background-size: cover;
    }

    &.news {
      background: url("./assets/images/banner@2x.png") top center no-repeat;
      background-size: cover;
    }

    &.contact {
      background: url("./assets/images/banner 拷贝@2x.png") top center no-repeat;
      background-size: cover;
    }

    &.about {
      background: url("./assets/images/banner1@2x.png") top center no-repeat;
      background-size: cover;
    }
  }

  .footer {
    background: #142041;
    padding-top: 65px;

    .footer1 {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }

    .footer2 {
      display: flex;
      justify-content: center;
      padding-bottom: 35px;
      border-bottom: solid 1px #29375e;
      margin-bottom: 15px;

      .footer2-title {
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.2;
      }

      .footer2-context {
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.5;
        margin-right: 60px;
      }
    }

    .footer3 {
      font-size: 16px;
      font-weight: 400;
      color: #fefefe;
      opacity: 0.2;
      text-align: center;
      margin-bottom: 15px;
    }

    .footer4 {
      display: flex;
      justify-content: center;
      margin-bottom: 15px;

      a {
        text-align: center;
      }
      img {
        width: 50%;
        height: auto;
      }
    }
  }
</style>
